import { IAccount } from '@medely/types';

export const isSaasAccount = (account?: IAccount) => Boolean(account?.company);

export const isSaasCredentialingAndSchedulingAccount = (account?: IAccount) =>
  isSaasAccount(account) && Boolean(account?.company?.saas_scheduling_enabled);

export const isSaasCredentialingAccount = (account?: IAccount) =>
  isSaasAccount(account) &&
  !isSaasCredentialingAndSchedulingAccount(account) &&
  Boolean(account?.company?.saas_credentialing_enabled);

export const isAgencyAccount = (account?: IAccount) => Boolean(account?.agency_id);
