import { CredentialStatus, ICondition, IProfessionalQualification } from '@medely/types';
import {
  UseMutateFunction,
  UseMutateAsyncFunction,
  useQuery,
  QueryFunctionContext,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { GET_PROFESSIONAL_QUALIFICATIONS } from '../graphql';
import { useMissingConditions } from './useMissingConditions';
import { useCurrentUser } from './useCurrentUser';
import {
  useEditProfessionalQualification,
  useSubmitPendingProfessionalQualification,
  useSubmitProfessionalQualification,
} from './useProfessionalQualification';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';

export const VIEWABLE_PQ_STATUSES: CredentialStatus[] = [
  'approved',
  'rejected',
  'expired',
  'review',
  'incomplete',
];

const DOCUMENT_TYPES = ['document', 'signed_document'];

type ProfessionalQualifications = {
  licenses: IProfessionalQualification[];
  certifications: IProfessionalQualification[];
  documents: IProfessionalQualification[];
  premiers: IProfessionalQualification[];
  missingPremiers: ICondition[];
  allProfessionalQualifications: IProfessionalQualification[];
  allMissingConditions: ICondition[];
  hasMissingConditions: boolean;
  facilityOnboarding?: IProfessionalQualification;
  completedFacilityOnboarding: boolean;
  isLoading: boolean;
  submitProfessionalQualification: UseMutateAsyncFunction<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
  submitPendingProfessionalQualification: UseMutateFunction<
    AxiosResponse<any>,
    unknown,
    any,
    unknown
  >;
  editProfessionalQualification: UseMutateFunction<AxiosResponse<any>, unknown, any, unknown>;
};

export const fetchProfessionalQualifications = async (
  queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
): Promise<IProfessionalQualification[]> => {
  const {
    queryKey: [_entity, search],
  } = queryContext;
  const { professionalQualifications } = await gqlRequest(GET_PROFESSIONAL_QUALIFICATIONS, {
    input: { search: { statuses: VIEWABLE_PQ_STATUSES, not_archived: true, ...(search ?? {}) } },
  });
  return professionalQualifications;
};

const fetchCurrentPremierQualifications = async (
  _queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
): Promise<IProfessionalQualification[]> => {
  const { professionalQualifications } = await gqlRequest(GET_PROFESSIONAL_QUALIFICATIONS, {
    input: { search: { premier_only: true, statuses: VIEWABLE_PQ_STATUSES, not_archived: true } },
  });
  return professionalQualifications;
};

export const useProfessionalQualifications = ({
  job_id,
  position_ids,
}: { job_id?: number; position_ids?: number[] } = {}): ProfessionalQualifications => {
  const { currentUser } = useCurrentUser();
  const { gqlRequest } = useNetworkRequest();

  const enabled = !!currentUser;

  const { edit, isEditing } = useEditProfessionalQualification();
  const { submit, isSubmitting } = useSubmitProfessionalQualification();
  const { submitPending, isSubmittingPending } = useSubmitPendingProfessionalQualification();

  const { conditions: missingConditions } = useMissingConditions({ job_id, position_ids });

  const { conditions: missingPremierConditions } = useMissingConditions({
    job_id,
    position_ids,
    is_premier: true,
  });

  const { data: currentProfessionalQualifications = [], isInitialLoading: fetching } = useQuery(
    ['professionalQualifications'],
    (queryContext) => fetchProfessionalQualifications(queryContext, gqlRequest),
    { enabled, refetchInterval: 15000 },
  );

  const { data: currentPremierQualifications = [], isInitialLoading: fetchingCurrentPrem } =
    useQuery(
      ['allPremierQualifications'],
      (queryContext) => fetchCurrentPremierQualifications(queryContext, gqlRequest),
      {
        enabled,
        refetchInterval: 15000,
      },
    );

  const credentialDetails = parseConditionsAndQualifications(currentProfessionalQualifications);

  return {
    ...credentialDetails,
    allMissingConditions: missingConditions,
    hasMissingConditions: Boolean(missingConditions.length),
    allProfessionalQualifications: currentProfessionalQualifications,
    completedFacilityOnboarding:
      !!credentialDetails.facilityOnboarding &&
      credentialDetails.facilityOnboarding.status === 'approved',
    editProfessionalQualification: edit,
    isLoading: fetching || fetchingCurrentPrem || isSubmitting || isEditing || isSubmittingPending,
    missingPremiers: missingPremierConditions,
    premiers: currentPremierQualifications,
    submitProfessionalQualification: submit,
    submitPendingProfessionalQualification: submitPending,
  };
};

type GroupedPQs = {
  certifications: IProfessionalQualification[];
  documents: IProfessionalQualification[];
  signedDocuments: IProfessionalQualification[];
  facilityOnboarding?: IProfessionalQualification;
  licenses: IProfessionalQualification[];
};
const groupProfessionalQualifications = (
  currentProfessionalQualifications: IProfessionalQualification[],
): GroupedPQs => {
  const result: GroupedPQs = {
    certifications: [],
    documents: [],
    signedDocuments: [],
    facilityOnboarding: undefined,
    licenses: [],
  };

  currentProfessionalQualifications.forEach((pq) => {
    if (pq.qualification?.qualification_type === 'license') {
      result.licenses.push(pq);
      return;
    }

    if (['certification', 'q_and_a'].includes(pq.qualification?.qualification_type)) {
      result.certifications.push(pq);
      return;
    }

    if (DOCUMENT_TYPES.includes(pq.qualification.qualification_type)) {
      result.documents.push(pq);
      return;
    }

    if (pq.qualification.qualification_type === 'facility_onboarding') {
      result.facilityOnboarding = pq;
      return;
    }
  });

  return result;
};

const parseConditionsAndQualifications = (
  currentProfessionalQualifications: IProfessionalQualification[],
) => {
  return {
    ...groupProfessionalQualifications(currentProfessionalQualifications),
  };
};
