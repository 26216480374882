import { IProfessionalQualification } from '@medely/types';
import { useMutation, useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { GET_PROFESSIONAL_QUALIFICATION } from '../graphql';
import { useCurrentUser } from './useCurrentUser';
import { useAnalytics } from '@medely/ui-kit';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';

const fetchProfessionalQualification = async (
  queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
): Promise<IProfessionalQualification> => {
  const {
    queryKey: [_entity, id],
  } = queryContext;
  const { professionalQualification } = await gqlRequest(GET_PROFESSIONAL_QUALIFICATION, {
    id,
  });
  return professionalQualification;
};

export const useTrackSuccessfulPQSubmission = () => {
  const { currentUser } = useCurrentUser();
  const analytics = useAnalytics();

  return {
    track: (qualificationId: number) => {
      analytics.track('PQ Submitted', {
        professionalId: currentUser?.professional?.id,
        qualificationId,
      });
    },
  };
};

export const useEditProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();
  const { axios } = useNetworkRequest();

  const {
    mutateAsync: editAsync,
    mutate: edit,
    isLoading: isEditing,
  } = useMutation(
    (values: any) =>
      axios.put(`/v3/professional/professional_qualifications/${values.id}`, {
        ...values,
        id: undefined,
      }),
    {
      onSuccess: (_, variables) => {
        track(variables.professional_qualification.qualification_id);
      },
    },
  );

  return { edit, editAsync, isEditing };
};

export const useSubmitProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();
  const { axios } = useNetworkRequest();

  const { mutateAsync: submit, isLoading: isSubmitting } = useMutation(
    (values: any) => axios.post(`/v3/professional/professional_qualifications`, values),
    {
      onSuccess: (_, variables) => {
        track(variables.professional_qualification.qualification_id);
      },
    },
  );

  return { submit, isSubmitting };
};

export const useSubmitPendingProfessionalQualification = () => {
  const { track } = useTrackSuccessfulPQSubmission();
  const { axios } = useNetworkRequest();

  const { mutate: submitPending, isLoading: isSubmittingPending } = useMutation(
    ({ qualification_id }: { qualification_id: number; professional_id: number }) =>
      axios.post(`v3/professional/professional_qualifications/pending`, {
        professional_qualification: {
          qualification_id,
        },
      }),
    {
      onSuccess: (_, variables) => {
        track(variables.qualification_id);
      },
    },
  );

  return { submitPending, isSubmittingPending };
};

export const useProfessionalQualification = (id: number) => {
  const { gqlRequest } = useNetworkRequest();

  const { edit, editAsync, isEditing } = useEditProfessionalQualification();
  const { submit, isSubmitting } = useSubmitProfessionalQualification();
  const { submitPending, isSubmittingPending } = useSubmitPendingProfessionalQualification();

  const { data, isFetching } = useQuery(
    ['professionalQualification', id],
    (queryContext) => fetchProfessionalQualification(queryContext, gqlRequest),
    {
      enabled: !!id,
      staleTime: 0, // staletime set to 0 to ensure we can load images with not-expired url's
    },
  );

  return {
    edit,
    editAsync,
    isLoading: isEditing || isFetching || isSubmitting || isSubmittingPending,
    professionalQualification: data,
    submit,
    submitPending,
  };
};
