import { IAccount } from '@medely/types';
import useCurrentUser from 'hooks/useCurrentUser';
import { isSaasCredentialingAccount, isSaasAccount } from 'utils/account';
import config from './config';

interface RouteType {
  name: string;
  path: string;
  exact?: boolean;
  showIf?: (account: IAccount) => boolean;
}

const pathTo = (path) => `${config.root}/${path}`;
const isNotSaasAccount = (account: IAccount) => !isSaasAccount(account);
const isNotSaasCredentialingAccount = (account: IAccount) => !isSaasCredentialingAccount(account);
const isNotAgencyPro = (account: IAccount) => !account.agency_id;

const TODAY: RouteType = {
  name: 'Today',
  path: '/',
  exact: true,
  showIf: (account: IAccount) =>
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasCredentialingAccount(account),
};

const FIND_JOBS: RouteType = {
  name: 'Find Jobs',
  path: pathTo('available'),
  showIf: (account: IAccount) =>
    !['paused', 'paused_requirement_submitted', 'deactivated'].includes(
      account?.professional?.status,
    ) &&
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasCredentialingAccount(account),
};

const MY_SHIFTS: RouteType = {
  name: 'My Shifts',
  path: pathTo('schedule'),
  showIf: (account: IAccount) =>
    !['deactivated'].includes(account?.professional?.status) &&
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasCredentialingAccount(account),
};

const MY_ASSIGNMENTS: RouteType = {
  name: 'My Assignments',
  path: pathTo('my-assignments'),
  showIf: (account: IAccount) =>
    !['deactivated'].includes(account?.professional?.status) &&
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasCredentialingAccount(account) &&
    isNotAgencyPro(account),
};

const TIMESHEETS: RouteType = {
  name: 'Timesheets',
  path: pathTo('timesheets'),
  showIf: (account: IAccount) =>
    !['deactivated'].includes(account?.professional?.status) &&
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasCredentialingAccount(account) &&
    isNotAgencyPro(account),
};

const EARNINGS: RouteType = {
  name: 'Earnings',
  path: pathTo('earnings'),
  showIf: (account: IAccount) =>
    !['archived', 'rejected'].includes(account?.professional?.application_status) &&
    isNotSaasAccount(account) &&
    isNotAgencyPro(account),
};

const PROFILE: RouteType = {
  name: 'Profile',
  path: pathTo('profile'),
  showIf: (account: IAccount) =>
    !['deactivated'].includes(account?.professional?.status) &&
    !['archived', 'rejected'].includes(account?.professional?.application_status),
};

const SETTINGS: RouteType = {
  name: 'Settings',
  path: pathTo('settings'),
};

export const ONBOARDING: RouteType = {
  name: 'Onboarding',
  path: pathTo('onboarding'),
};

export const assignmentOnboardingRoute = (assignmentId): RouteType => {
  return {
    name: 'Onboarding',
    path: pathTo(`assignments/${assignmentId}/onboarding`),
  };
};

export const routes: RouteType[] = [
  TODAY,
  FIND_JOBS,
  MY_SHIFTS,
  MY_ASSIGNMENTS,
  TIMESHEETS,
  EARNINGS,
];

export const mobileRoutes: RouteType[] = [
  TODAY,
  FIND_JOBS,
  MY_SHIFTS,
  MY_ASSIGNMENTS,
  TIMESHEETS,
  EARNINGS,
  PROFILE,
];

export const mobileSettingsRoutes: RouteType[] = [SETTINGS];

export const mobilePageHeaders = [
  {
    header: 'Payout Summary',
    regExp: /\/earnings\/payouts\/[0-9]/,
  },
  {
    header: 'Completed Job Details',
    regExp: /\/earnings\/jobs\/[0-9]/,
  },
  {
    header: 'Earnings',
    regExp: /\/earnings/,
  },
  {
    header: 'Assignment',
    regExp: /\/assignments\/[0-9]/,
  },
  {
    header: 'Assignments',
    regExp: /\/assignments\/[^0-9]/,
  },
  {
    header: 'Find Jobs',
    regExp: /\/available\//,
  },
  {
    header: 'Available Assignments',
    regExp: /\/jobs\/assignments/,
  },
  {
    header: 'Job',
    regExp: /\/jobs\/[0-9]/,
  },
  {
    header: 'Jobs',
    regExp: /\/jobs\/[^0-9]/,
  },
  {
    header: 'My Assignments',
    regExp: /\/my-assignments/,
  },
  {
    header: 'Credentials',
    regExp: /\/credentials$/,
  },
  {
    header: 'Credential Status',
    regExp: /\/credentials\/status$/,
  },
  {
    header: 'Profile',
    regExp: /\/profile/,
  },
  {
    header: 'My Shifts',
    regExp: /\/schedule/,
  },
  {
    header: 'Timesheets',
    regExp: /\/timesheets/,
  },
  {
    header: 'Settings',
    regExp: /\/settings/,
  },
  {
    header: 'Access W-2 shifts',
    regExp: /\/onboarding\/w2/,
  },
  {
    header: 'Onboarding',
    regExp: /\/onboarding/,
  },
  {
    header: 'Today',
    regExp: /\/$/,
  },
];

const filterRoutes = (routes, account) =>
  routes.filter((route) => {
    if (!route.showIf) {
      return route;
    }
    return route.showIf(account);
  });

export const useRoutes = () => {
  const { currentUser } = useCurrentUser();
  return {
    desktop: filterRoutes(routes, currentUser),
    mobile: filterRoutes(mobileRoutes, currentUser),
  };
};

export default routes;
