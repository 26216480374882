import { gql } from 'graphql-request';

export const GET_REFERRAL_SOURCES = gql`
  query ReferralSources($input: ReferralSourceQueryInput) {
    referralSources(input: $input) {
      id
      label
    }
  }
`;
