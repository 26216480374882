import { gql } from 'graphql-request';

export const GET_STATES = gql`
  query States($input: StateQueryInput) {
    states(input: $input) {
      id
      abbreviation
      name
      square_image
      rectangle_image
      google_place_id
    }
  }
`;

export const GET_STATE = gql`
  query State($id: Int!) {
    state(id: $id) {
      id
      name
    }
  }
`;
