import { gql } from 'graphql-request';

const ASSIGNMENT_FRAGMENT = gql`
  fragment assignmentFields on Assignment {
    id
    starts_date
    ends_date
    starts_time
    ends_time
    number_of_shifts_per_week
    hours_per_shift
    positions {
      display_name
    }
    primary_location {
      name
      address_city
      address_zip
      state {
        id
        abbreviation
      }
      timezone_lookup
    }
    pay_per_week_cents
    track_unpaid_breaks
    call_requirements
    specialties {
      id
      label
    }
    one_time_bonus_burden_cents
    vms_partnership
  }
`;

export const GET_ASSIGNMENT_REQUESTS = gql`
  query AssignmentRequests($input: AssignmentRequestQueryInput) {
    assignmentRequests(input: $input) {
      id
      status
      assignment {
        ...assignmentFields
        missing_condition_ids
        is_w2
        is_travel
        primary_location {
          location_type {
            label
          }
        }
      }
      created_at
    }
    _assignmentRequestsMeta(input: $input) {
      count
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

export const GET_PENDING_ASSIGNMENT_REQUESTS = gql`
  query PendingAssignmentRequests($input: AssignmentRequestQueryInput) {
    assignmentRequests(input: $input) {
      id
      assignment {
        ...assignmentFields
        missing_condition_ids
      }
      status
    }
    _assignmentRequestsMeta(input: $input) {
      count
    }
  }
  ${ASSIGNMENT_FRAGMENT}
`;

export const GET_MISSING_ASSIGNMENT_CHECKLIST_INFORMATION = gql`
  query AssignmentChecklist(
    $has_missing_availability: Boolean = false
    $has_missing_education: Boolean = false
    $has_missing_ehr_software: Boolean = false
    $has_missing_experience: Boolean = false
    $has_missing_identity: Boolean = false
    $has_missing_information: Boolean = false
    $has_missing_references: Boolean = false
    $has_missing_work_history: Boolean = false
    $has_unexplained_work_gaps: Boolean = false
    $input: AssignmentRequestQueryInput
  ) {
    assignmentRequests(input: $input) {
      assignment_id
      has_missing_availability @include(if: $has_missing_availability)
      has_missing_education @include(if: $has_missing_education)
      has_missing_ehr_software @include(if: $has_missing_ehr_software)
      has_missing_experience @include(if: $has_missing_experience)
      has_missing_identity @include(if: $has_missing_identity)
      has_missing_information @include(if: $has_missing_information)
      has_missing_references @include(if: $has_missing_references)
      has_missing_work_history @include(if: $has_missing_work_history)
      has_unexplained_work_gaps @include(if: $has_unexplained_work_gaps)
    }
  }
`;

export const GET_ASSIGNMENT_REQUEST_AVAILABILITY = gql`
  query AssignmentRequests($input: AssignmentRequestQueryInput) {
    assignmentRequests(input: $input) {
      id
      status
      assignment {
        primary_location {
          address_city
          state {
            abbreviation
          }
          timezone_lookup
        }
        starts_date
        ends_date
      }
      created_at
      asap
      time_off_request
      interview_availability
      preferred_start_date
    }
    _assignmentRequestsMeta(input: $input) {
      count
    }
  }
`;

export const GET_ASSIGNMENT_REQUEST_WORK_HISTORIES = gql`
  query AssignmentRequests($id: Int!) {
    assignmentRequest(id: $id) {
      id
      assignment {
        positions {
          id
        }
      }
      professional_position_recent_experience(within_past_years: 3) {
        position_id
        has_experience
      }
    }
  }
`;
