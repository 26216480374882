import { gql } from 'graphql-request';

export const GET_LOCATION_TYPES = gql`
  query LocationTypes($input: LocationTypeQueryInput) {
    locationTypes(input: $input) {
      id
      label
    }
  }
`;
