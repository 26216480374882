export const gateNames = {
  displayJobCancelButtonAtPageTop: 'bring_cancel_button_to_top_of_job_details_page',
  findJobsInfiniteScroll: 'find_jobs_infinite_scroll',
  inAppDisputeResolution: 'pro_in_app_dispute_resolution',
  proCardRedesign: 'pro_job_card_redesign',
  proClockOutSupervisorSignature: 'pro_clock_out_supervisor_signature',
  proClockOutSupervisorSignatureOT: 'pro_clock_out_supervisor_signature_ot',
  proGraphqlTransformations: 'pro_graphql_transformations',
  qualificationAutomationEnabled: 'credential_automation_ui',
  showMileageInJobDetails: 'show_mileage_in_job_details',
  topLevelBookButton: 'top_level_book_button',
  tosOn: 'terms_of_service',
  travelQualifications: 'pro_travel_qualifications',
  travelQualificationsImprovements: 'pro_travel_qualifications_improvements',
};

export const experimentNames = {
  ahaEcode: 'aha_ecode',
};

export const valueNames = {
  NEW_CARDS: 'new_cards',
};
