import { Box } from '@medely/web-components';
import Chat from './Chat';
import NavBar from '../NavBar';
import React from 'react';
import SettingsDropdown from '../SettingsDropdown/SettingsDropdown';
import useCurrentUser from 'hooks/useCurrentUser';
import { isSaasAccount, isAgencyAccount } from 'utils/account';
import { Logo } from 'components/Logo';

const TopBarDesktop = (): React.ReactElement => {
  const { isApplicant, currentUser } = useCurrentUser();
  const showNavBar = !isApplicant || isSaasAccount(currentUser) || isAgencyAccount(currentUser);
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        py: 1,
        px: 2,
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box sx={{ pt: 1, pr: 1 }} data-testid="header-logo">
          <Logo isSaasAccount={isSaasAccount(currentUser)} company={currentUser.company} />
        </Box>
        {showNavBar && <NavBar />}
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Chat />
        <SettingsDropdown />
      </Box>
    </Box>
  );
};

export default TopBarDesktop;
