import { Box, Button, Link, Typography } from '@medely/web-components';
import show from 'utils/Intercom/show';
import React from 'react';
import { notifyRollbar } from 'utils/Rollbar/notifyRollbar';
import { Logo } from 'components/Logo';

export default class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  state = {
    hasError: false,
  };

  static ErrorMessage = () => (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          marginLeft: 24,
          marginTop: 16,
        }}
      >
        <Logo />
      </Box>

      <div style={{ textAlign: 'center' }}>
        <Typography>Uh oh. You have encountered an error.</Typography>
        <Typography>
          Head to our <Link href="/">homepage</Link>
          , or ask for
          <br /> help with our{' '}
          <Button variant="link" onClick={() => show()}>
            messenger
          </Button>
          .
        </Typography>
      </div>
    </div>
  );

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
      notifyRollbar({ errorLabel: error, args: info });
    }
  }

  public render() {
    if (this.state.hasError) {
      return <ErrorBoundary.ErrorMessage />;
    }
    // @ts-ignore
    return this.props.children;
  }
}
