import { gql } from 'graphql-request';

export const GET_ACTIVE_SURGERY_TYPES = gql`
  query SurgeryTypes($input: SurgeryTypeQueryInput) {
    surgeryTypes(input: $input) {
      id
      display_name
    }
  }
`;
