import React from 'react';
import { MedelyLogo } from '@medely/web-components/icons';
import { ICompany } from '@medely/types';
import { CompanyLogo } from './CompanyLogo';

interface LogoProps {
  isSaasAccount?: boolean;
  company?: ICompany;
  width?: string;
  height?: string;
}

export const Logo: React.FC<LogoProps> = ({ isSaasAccount, company, ...props }) => {
  return isSaasAccount ? (
    <CompanyLogo company_name={company.name} logo={company.logo} />
  ) : (
    <MedelyLogo {...props} />
  );
};
