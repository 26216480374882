import { useState } from 'react';

const useDialog = (openedByDefault = false) => {
  const [open, setOpen] = useState(openedByDefault);
  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  return {
    open,
    openDialog,
    closeDialog,
    dialogProps: {
      open,
      onClose: closeDialog,
    },
  };
};

export default useDialog;
