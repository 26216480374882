import { gql } from 'graphql-request';

export const GET_PROFILE_STATS = gql`
  query ProfileStats {
    profileStats {
      no_shows_count
      late_cancellations_count
      do_not_sends_count
      favorites_count
      jobs_completed_count
      status
    }
  }
`;
