import React, { useState } from 'react';
import { Avatar, Button, Menu, MenuItem } from '@medely/web-components';
import { useFirebaseContext, useConfigurationContext } from '@medely/ui-kit';
import { ChevronDown } from '@medely/web-components/icons';
import useCurrentUser from 'hooks/useCurrentUser';
import openInNewTab from 'utils/openInNewTab';
import { MarketplaceSettingsMenu } from './Menus/MarketplaceSettingsMenu';
import { IrpSettingsMenu } from './Menus/IrpSettingsMenu';
import { AgencySettingsMenu } from './Menus/AgencySettingsMenu';

export type SettingsMenuItemsProps = {
  handleClose: () => void;
};

const SettingsDropdown = () => {
  const { help } = useConfigurationContext();
  const { currentUser, isAgency, isSaas } = useCurrentUser();
  const { logout } = useFirebaseContext();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHelp = () => {
    // @ts-expect-error TODO: remove after typings update
    const helpUrl = isAgency ? help.agencyUrl : help.url;
    openInNewTab(helpUrl);
    handleClose();
  };

  const settingsMenuItems = () => {
    if (isSaas) {
      return <IrpSettingsMenu handleClose={handleClose} />;
    } else if (isAgency) {
      return <AgencySettingsMenu handleClose={handleClose} />;
    } else {
      return <MarketplaceSettingsMenu handleClose={handleClose} />;
    }
  };

  return (
    <>
      <Button onClick={handleClick} endIcon={<ChevronDown />} data-testid="header-dropdown">
        <Avatar name={currentUser?.name} />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        data-testid="dropdown-options"
      >
        {settingsMenuItems()}
        <MenuItem onClick={handleHelp}>Help</MenuItem>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default SettingsDropdown;
