import React from 'react';
import { Box } from '@mui/material';

interface CompanyLogoProps {
  company_name: string;
  logo: string;
}

export const CompanyLogo: React.FC<CompanyLogoProps> = ({ company_name, logo }) => {
  return (
    logo && (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100px',
          height: '32px',
          overflow: 'hidden',
        }}
      >
        <img
          src={logo}
          alt={company_name}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Box>
    )
  );
};
