import { gql } from 'graphql-request';

export const GET_POSITIONS_AND_SPECIALTIES = gql`
  query LocationPositionRateSettings($input: LocationPositionRateSettingQueryInput) {
    locationPositionRateSettings(input: $input) {
      id
      position {
        id
        display_name
        kind
        requires_specialty
      }
      specialty {
        id
        label
        name
      }
    }
  }
`;

export const GET_ALL_POSITIONS = gql`
  query AllPositions($input: PositionQueryInput) {
    positions(input: $input) {
      id
      display_name
      qualifications_group_id
    }
  }
`;

export const GET_POSITION = gql`
  query Position($id: Int!) {
    position(id: $id) {
      id
      lead_question
      lead_description
      requires_specialty
      specialties {
        id
        label
        active
      }
    }
  }
`;
