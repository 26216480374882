import { Badge, Box, NavLink } from '@medely/web-components';
import React from 'react';
import { useRoutes } from 'routes';
import useMissingConditions from 'hooks/useMissingConditions';
import useOnboarding from 'hooks/useOnboarding';
import useCurrentUser from 'hooks/useCurrentUser';

const NavBar = () => {
  const { assignmentsMissingConditions, isAssignmentOnboardingRequired, showOnboarding } =
    useOnboarding();
  const { conditions } = useMissingConditions();
  const routes = useRoutes();
  const { isArchived, isDeactivated, isRejected } = useCurrentUser();
  const hasActiveApplication = !isDeactivated && !isArchived && !isRejected;

  return (
    <Box display="flex" flexDirection="row" data-testid="header-navbar">
      {(showOnboarding || isAssignmentOnboardingRequired) && hasActiveApplication && (
        <NavLink
          to={
            isAssignmentOnboardingRequired
              ? `/assignments/${assignmentsMissingConditions[0]}/onboarding`
              : '/onboarding'
          }
        >
          Onboarding
        </NavLink>
      )}
      {routes.desktop.map((r) =>
        r.name === 'Credentials' && hasActiveApplication ? (
          <Box key={r.path}>
            <Badge content={conditions.length} sx={{ top: '10px', right: '10px' }}>
              <NavLink to={r.path} exact={r.exact}>
                {r.name}
              </NavLink>
            </Badge>
          </Box>
        ) : (
          <Box key={r.path}>
            <NavLink to={r.path} exact={r.exact}>
              {r.name}
            </NavLink>
          </Box>
        ),
      )}
    </Box>
  );
};

export default NavBar;
