import { gql } from 'graphql-request';

export const GET_PROFESSIONAL_RATES = gql`
  query MarketPositionRateSettings($input: MarketPositionRateSettingQueryInput) {
    marketPositionRateSettings(input: $input) {
      specialty_id
      professional_rate
    }
  }
`;
