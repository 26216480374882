import React, { useEffect } from 'react';
import { CircularProgress } from '@medely/web-components';

const LocationRedirect = ({ host, location }): React.ReactElement => {
  useEffect(() => {
    const redirect = `${host}${location.pathname}${location.search}`;
    window.location.href = redirect;
  }, []);
  return <CircularProgress />;
};

export default LocationRedirect;
