import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useProfessionalQualifications } from '@src/hooks/useProfessionalQualifications';
import { ConditionSatisfactionHelper, SatisfactionOption } from '@medely/credentials-tools';
import type { ICondition, IProfessionalQualification } from '@medely/types';

export type ConditionOption = {
  description: string;
  willBeSatisfied: boolean;
  condition: { qualifications: SatisfactionOption['qualifications'] };
};

const calculateMissingOptions = ({
  condition,
  professionalQualifications,
  satisfiedConditionIds,
}: {
  condition: ICondition;
  professionalQualifications: IProfessionalQualification[];
  satisfiedConditionIds: number[];
}): ConditionOption[] => {
  const conditionHelper = new ConditionSatisfactionHelper({
    condition,
    professionalQualifications,
    satisfiedConditionIds,
  });

  return conditionHelper.availableSatisfactionOptions.reduce(
    (acct: any, option: SatisfactionOption) => {
      const qualifications = option.qualifications.map((q) => q.qualification);
      if (!qualifications.length) {
        return acct;
      }
      const willBeSatisfied = option.qualifications.every((q) => !q.isMissing);
      return [
        ...acct,
        {
          description: option.description,
          willBeSatisfied,
          condition: { qualifications },
        },
      ];
    },
    [],
  );
};

const useMissingConditionOptions = (condition: ICondition) => {
  const { currentUser } = useCurrentUser();
  const { allProfessionalQualifications } = useProfessionalQualifications();

  const options = calculateMissingOptions({
    condition,
    professionalQualifications: allProfessionalQualifications,
    satisfiedConditionIds: currentUser?.professional?.satisfied_condition_ids ?? [],
  });

  return options;
};

export default useMissingConditionOptions;
