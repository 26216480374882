import { useProfessionalQualifications } from '../../../hooks/useProfessionalQualifications';
import { sortQualifcationAttributes } from '../../../utils/professionalQualifications';
import type { IQualification } from '@medely/types';
import { ConditionOption } from './useMissingConditionOptions';

const useMissingConditionFormValues = (condition?: ConditionOption['condition']) => {
  const { allProfessionalQualifications } = useProfessionalQualifications();

  const findExistingQualification = (qualification?: IQualification) =>
    [...(allProfessionalQualifications || [])]
      .sort((a, b) => (a.id > b.id ? -1 : 1))
      .find((pq) => pq.qualification.id === qualification?.id);

  if (!condition) {
    return { findExistingQualification };
  }

  const qualifications = condition.qualifications.map((q) => {
    // @ts-ignore
    const existingProfessionalQualification = findExistingQualification(q);
    if (!existingProfessionalQualification) {
      return q;
    }
    // @ts-ignore
    const qualificationAttributes = q.qualification_attributes.map((attribute) => {
      const pqAttribute =
        existingProfessionalQualification.professional_qualification_attributes.find(
          // @ts-ignore
          (pqa) => pqa.qualification_attribute_id === attribute.id,
        );
      return {
        ...attribute,
        value: pqAttribute?.value,
      };
    });
    const sortedQualificationAttributes =
      // @ts-ignore
      q.qualification_type === 'q_and_a'
        ? qualificationAttributes
        : qualificationAttributes.sort(sortQualifcationAttributes);
    return {
      ...q,
      qualification_attributes: sortedQualificationAttributes,
    };
  });

  return { values: { ...condition, qualifications }, findExistingQualification };
};

export default useMissingConditionFormValues;
