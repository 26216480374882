import React, { ReactNode, createContext, useContext } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import config from 'config';

interface IContext {
  loader: Loader;
}

const GooglePlacesLoaderContext = createContext({
  loader: undefined,
});

export const GooglePlacesLoaderProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement => {
  const loader = new Loader({
    apiKey: config.googleMapsKey,
  });

  return (
    <GooglePlacesLoaderContext.Provider value={{ loader }}>
      {children}
    </GooglePlacesLoaderContext.Provider>
  );
};

export const useGooglePlacesLoaderContext = (): IContext => useContext(GooglePlacesLoaderContext);
