import { useGraphQLContext } from '@medely/ui-kit/web';
import gqlRequest from 'utils/networkRequests/gqlRequest';

type UseGraphqlRequestResult<T> = {
  gqlRequest: (query: string, variables?: unknown) => Promise<T>;
  request: (query: string, variables?: unknown) => Promise<T>;
};

const useGraphQLRequest = <T = any>(): UseGraphqlRequestResult<T> => {
  const { transform } = useGraphQLContext();

  const request = async (query, variables?: any) => {
    return gqlRequest<T>(transform(query), variables);
  };

  return { gqlRequest: request, request };
};

export default useGraphQLRequest;
