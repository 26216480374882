import isDevOrStaging from './utils/isDevOrStaging';

const config = {
  apiSuffix: '',
  breakpoints: {
    extraSmall: 480,
    small: 768,
    medium: 992,
    large: 1200,
  },
  notificationsLimit: 5,
  help: {
    url: 'https://help.medely.com',
    agencyUrl: 'https://help-partners.medely.com',
    referral: 'https://help.medely.com/articles/374955-medely-referral-program',
    medely_professionals: 'https://help.medely.com/collections/127398-medely-professionals',
    signing_up: 'https://help.medely.com/collections/127521-signing-up',
    background_check_pending:
      'https://help.medely.com/en/articles/3844099-why-is-my-background-check-still-pending',
    covid_requirements:
      'https://help.medely.com/en/articles/5497289-covid-vaccine-booster-or-test-protocol-when-required-by-state-or-facility',
    communityGuidelines: 'https://help.medely.com/en/articles/4564959-community-guidelines',
    sms: 'https://help.medely.com/en/articles/5322046-sms-terms',
    privacyPolicy: 'https://medely.com/privacy-policy',
    clockingInOut: 'https://help.medely.com/en/articles/103312-clocking-in-out',
    frontlineW2Shifts: 'https://intercom.help/frontline-health/en/articles/8464674-w-2-shifts',
  },
  persist: {
    blacklist: [
      'notifications',
      'form',
      'data',
      'browser',
      'constants',
      'admin',
      'person',
      'entities',
      'impersonate',
      'staff',
    ],
    key: 'root',
  },
  professionalApplicationPath: '/professionals/application',
  root: '',
  staffOnboardingPath: '/onboarding',
  testTypeform: 'p4ayvY',
  userServiceAgreement: '/facility_terms',
  professionalTermsPathname: 'https://medely.com/professional_terms',
  calendlyAssignmentURL: isDevOrStaging()
    ? 'https://calendly.com/development-phone-screening/development-phone-screening-2'
    : 'https://calendly.com/phone-screening/assignments',
  calendlyURL: isDevOrStaging()
    ? 'https://calendly.com/ryan-1525/30min'
    : 'https://calendly.com/phone-screening/phone-screening',
  resumeExtensions: ['.doc', '.docx', '.pdf', '.txt'],
  payoutExtensions: ['.jpg', '.png', '.doc', '.docx', '.pdf'],
  feature: {
    payouts: true,
  },
  adminUrl: process.env.REACT_APP_ADMIN_URL || 'http://localhost:5000',
  facilityUrl: process.env.REACT_APP_FACILITY_URL || 'http://localhost:3500',
  itemUrl: process.env.REACT_APP_ITEM_URL,
  landingsUrl: process.env.REACT_APP_LANDINGS_URL || 'http://localhost:3001',
  localStorage: {
    tokenKey: 'medely-token',
  },
  stripePublishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  tokenExpMs: 86400000, // 24h
  assignmentReferralTerms: 'https://help.medely.com/en/articles/4809046-assignment-referrals',
  helloSignId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID,
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyBFXF0RZ_8RFi62eNEnuNzscb8i60JlsQw',
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'medely-staging.firebaseapp.com',
  },
  segmentWriteKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
  rollbar: {
    postClientItemKey:
      process.env.REACT_APP_ROLLBAR_CLIENT_KEY || '020d70f3b088491db3b62035212a8eaf',
  },
  accountPausedTypeformId: isDevOrStaging() ? 'k3EYha' : 'NJTgkr',
  signUpKey: process.env.REACT_APP_SIGNUP_KEY || 'zB9NVETcO9!%',
  googleMapsKey: process.env.REACT_APP_MAPS_API_KEY ?? 'AIzaSyB1-c4n-lFxIFF9EhCiJUqISOCdwQsVn1o',
};

export default config;
