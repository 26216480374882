import { gql } from 'graphql-request';

export const GET_EHRS = gql`
  query EHRs($input: EhrSystemQueryInput) {
    ehrSystems(input: $input) {
      id
      label
    }
  }
`;
