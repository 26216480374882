import { Box, CircularProgress } from '@medely/web-components';
import { lightTheme, ThemeProvider } from '@medely/web-components/themes';
import AppRouter from 'AppRouter';
import { ReactQueryProvider } from 'context/reactQueryContext';
import React, { ReactNode } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './styles/global.css';
import { default as configuration } from 'config';
import { StripeProvider } from '@medely/web-components/contexts';
import { FeatureGateProvider, useFeatureGateContext } from 'context/featureGateContext';
import GraphQLPermissionsContextProvider, {
  useGraphQLPermissionsContext,
} from 'context/graphqlContext';
import { useFirebaseContext } from '@medely/ui-kit/web';

const App = () => {
  return (
    <ReactQueryProvider>
      <FeatureGateProvider>
        <GraphQLPermissionsContextProvider>
          <ThemeProvider theme={lightTheme}>
            <StripeProvider stripeKey={configuration.stripePublishableKey}>
              <LoadingBox>
                <AppRouter />
              </LoadingBox>
            </StripeProvider>
          </ThemeProvider>
        </GraphQLPermissionsContextProvider>
      </FeatureGateProvider>
    </ReactQueryProvider>
  );
};

const LoadingBox = ({ children }: { children: ReactNode }) => {
  const { loading: isLoadingFirebase } = useFirebaseContext();
  const { isLoading: isLoadingFeatureGateContext } = useFeatureGateContext();
  const { isLoading: isLoadingGraphQLPermissions } = useGraphQLPermissionsContext();

  if (isLoadingFirebase || isLoadingGraphQLPermissions || isLoadingFeatureGateContext) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return <>{children}</>;
};

export default App;
