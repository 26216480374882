import { Link, Typography } from '@medely/web-components';
import React, { ReactElement, ReactNode } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import { MedelyLogo } from '@medely/web-components/icons';
import { useTheme } from '@medely/web-components/themes';

const ProArchivedView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Typography variant="h5" mt={2}>
      Thank you for your interest in joining Medely!
    </Typography>
    <Typography mt={2}>
      At this time, your application has not been accepted because you do not meet our requirements
      for work experience and/or education.
    </Typography>
    <Typography mt={2}>
      For details about the specific requirements for the position you applied for, please review
      the relevant article in our{' '}
      <Link
        href="https://help.medely.com/en/collections/1942707-rn-allied-position-descriptions"
        target="_blank"
      >
        Help Center
      </Link>
      .
    </Typography>
    <Typography mt={2}>
      Once you feel that you meet the requirements, we welcome you to re-apply.
    </Typography>
  </>
);

const ProRejectedView = ({ theme }) => (
  <>
    <MedelyLogo color={theme.palette.primary.main} />
    <Typography variant="h5" mt={2}>
      Thank you for your interest in joining Medely.
    </Typography>
    <Typography mt={2}>
      At this time, your application has been declined because you do not meet one or more of
      Medely&apos;s requirements.
    </Typography>
  </>
);

interface IProViewProps {
  children?: ReactNode | ReactNode[];
}

const ProViewContent = ({ children }: IProViewProps): ReactElement => {
  const { isArchived, isRejected } = useCurrentUser();
  const theme = useTheme();

  if (isArchived) {
    return <ProArchivedView theme={theme} />;
  } else if (isRejected) {
    return <ProRejectedView theme={theme} />;
  } else {
    return <>{children}</>;
  }
};

export default ProViewContent;
