// @ts-nocheck
import { notifyRollbar } from 'utils/Rollbar/notifyRollbar';

export default function intercomMethod(...args) {
  if (typeof window !== 'undefined' && typeof window.Intercom === 'function') {
    try {
      window.Intercom(...args);
    } catch (e) {
      console.error(e);
      notifyRollbar({ errorLabel: 'Error triggering intercom event', args });
    }
  }
}
