import { Box } from '@medely/web-components';
import React from 'react';
import TopBarDesktop from './TopBarDesktop';
import TopBarMobile from './TopBarMobile';
import useDevice from 'hooks/useDevice';

const TopBarDefault = (): React.ReactElement => {
  const isDesktop = useDevice() === 'desktop';
  return (
    <Box width="100%" data-testid="header">
      {isDesktop ? <TopBarDesktop /> : <TopBarMobile />}
    </Box>
  );
};

export default TopBarDefault;
