import React, { useEffect } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import useExtole from 'hooks/useExtole';

declare global {
  interface Window {
    extole: any;
  }
}

type Id =
  | 'advocate_stats_embedded'
  | 'application_page'
  | 'confirmation'
  | 'earnings_page'
  | 'global_header'
  | 'global_footer'
  | 'in_interview_share_experience'
  | 'job_page'
  | 'mobile_header'
  | 'mobile_footer'
  | 'mobile_menu'
  | 'overlay'
  | 'product';

interface Props {
  id: Id;
  data?: Record<string, any>;
}

const elementId = (id: Id): string => `extole_zone_${id}`;

export const ReferralTag = ({ id, data }: Props): React.ReactElement => {
  const { currentUser } = useCurrentUser();
  const extole = useExtole();

  useEffect(() => {
    extole.setup().then((isAvailable) => {
      if (isAvailable) {
        window.extole?.createZone({
          name: id,
          element_id: elementId(id),
          data: {
            ...data,
            first_name: currentUser.first_name,
            last_name: currentUser.last_name,
            email: currentUser.email,
            partner_user_id: currentUser.id,
            market_ids: currentUser.professional?.markets?.map((market) => market?.id),
            position_ids: currentUser.professional?.positions?.map((pos) => pos?.id),
          },
        });
      }
    });
  }, [id, data, currentUser]);
  return <span id={elementId(id)} />;
};
