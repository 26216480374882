import { useFirebaseContext } from '@medely/ui-kit';
import axios, { AxiosRequestConfig } from 'axios';
import { User } from 'firebase/auth';
import { GraphQLClient, RequestDocument } from 'graphql-request';
import { TypedDocumentNode } from '@graphql-typed-document-node/core';

export type GqlRequest = (
  query: RequestDocument | TypedDocumentNode,
  variables?: any,
) => Promise<{
  [key: string]: any;
}>;

const RAILS_URL = process.env.REACT_APP_API_URL || process.env.EXPO_PUBLIC_API_URL;
const GQL_URL = process.env.REACT_APP_GRAPHQL_URL || process.env.EXPO_PUBLIC_GRAPHQL_URL;

const createAxiosInstance = (googleUser: Partial<User> | null) => {
  const setAxiosAuthHeader = async (config: AxiosRequestConfig) => {
    const token = await googleUser?.getIdToken?.();
    config.headers = { Authorization: `Bearer ${token}` };
    return config;
  };
  const axiosInstance = axios.create({
    baseURL: RAILS_URL,
  });
  axiosInstance.interceptors.request.use(setAxiosAuthHeader, (error) => {
    return Promise.reject(error);
  });
  return axiosInstance;
};

export const useNetworkRequest = () => {
  const { googleUser } = useFirebaseContext();

  const gqlRequest: GqlRequest = async (
    query: RequestDocument | TypedDocumentNode,
    variables = {},
  ) => {
    const token = await googleUser?.getIdToken?.();
    const client = new GraphQLClient(`${GQL_URL}/graphql`, {
      headers: token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {},
    });

    return client.request(query, variables);
  };

  const axios = createAxiosInstance(googleUser);
  return {
    axios,
    gqlRequest,
  };
};
