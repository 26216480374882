import { gql } from 'graphql-request';

export const GET_PRO_EMERGENCY_CONTACTS = gql`
  query EmergencyContacts($input: EmergencyContactQueryInput) {
    emergencyContacts(input: $input) {
      id
      first_name
      last_name
      phone_number
      professional_id
    }
  }
`;
