import { gql } from 'graphql-request';
import {
  DEFAULT_GRAPHQL_FRAGMENT,
  type GraphQLFragment,
  type IsAgencyAccountInput,
  isAgencyAccount,
} from '../utils';

export const getPayoutQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplacePayoutFragment',
      fragment: gql`
        fragment MarketplacePayoutFragment on Payout {
          amount_cents
          assignment {
            payout_base_hourly_rate_cents
          }
          payments {
            amount_cents
          }
          jobs {
            job_billing_sums: job_billing_sums_for_payout(payout_id: $id) {
              payout_total_amount_cents
            }
          }
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query Payout($id: Int!) {
      payout(id: $id) {
        ${fragment?.name}
        arrival_date
        assignment {
          ends_date
          hours_per_shift
          is_travel
          is_w2
          number_of_shifts_per_week
          primary_location {
            id
            name
            timezone_lookup
          }
          require_physical_signature
          starts_date
          track_unpaid_breaks
        }
        assignment_id
        ends_time
        expense_adjustments {
          amount_cents
          created_at
          id
          job_id
          taxable_type
        }
        id
        job_billings {
          id
          job_id
          total_hours
        }
        kind
        payments {
          arrival_date
          id
          status
        }
        starts_time
        status
        w2_submit_by_time

        jobs {
          assignment {
            float_requirements
            is_w2
            primary_location {
              id
            }
          }
          breaks {
            duration_minutes
            id
            starts_time
            waived
          }
          cancellation_fee_charge_hours
          cancellation_reason_id
          current_ends_time
          current_starts_time
          expense_adjustments {
            amount_cents
            created_at
            taxable_type
          }
          guaranteed_minimum_hours
          id
          job_billing_sums: job_billing_sums_for_payout(payout_id: $id) {
            total_hours
          }
          last_cancellation {
            other_reason
          }
          location {
            id
            name
            timezone_lookup
            unit
          }
          shift_type
          starts_date
          status
          timecard_id
        }
      }
    }
  `;
};

export const getPayoutsQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplacePayoutsFragment',
      fragment: gql`
        fragment MarketplacePayoutsFragment on Payout {
          amount_cents
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query Payouts($input: PayoutQueryInput) {
      payouts(input: $input) {
        ${fragment?.name}
        assignment {
          ends_date
          ends_time
          float_requirements
          is_travel
          is_w2
          locations {
            id
            name
            unit
          }
          primary_location {
            full_address
            id
            name
            timezone_lookup
          }
          require_physical_signature
          starts_date
          starts_time
          status
          track_unpaid_breaks
        }
        assignment_id
        ends_time
        id
        job_billings {
          id
          job_id
          total_hours
        }
        jobs {
          current_ends_time
          current_starts_time
          ends_date
          ends_time
          id
          location {
            name
          }
          shift_type
          starts_date
          starts_time
          status
          timecard_id
        }
        kind
        starts_time
        status
        w2_submit_by_time
      }
      _payoutsMeta(input: $input) {
        count
      }
    }
  `;
};
