import useGraphQLRequest from './useGraphQLRequest';
import { IProfessionalTodos } from '@medely/types';
import { GET_TODOS } from '@medely/pro-multi-platform';
import { useQuery } from '@tanstack/react-query';
import useCurrentUser from './useCurrentUser';

interface IProfessionalTodosProps {
  todos: IProfessionalTodos;
  isLoading: boolean;
}

const useProfessionalTodos = (): IProfessionalTodosProps => {
  const { currentUser } = useCurrentUser();
  const { request } = useGraphQLRequest();
  const enabled = !!currentUser;

  const fetchTodos = async (): Promise<IProfessionalTodos> => {
    const { professionalTodos } = await request(GET_TODOS);
    return professionalTodos;
  };

  const { data: todos, isLoading } = useQuery(['todos'], fetchTodos, { enabled });

  return { todos, isLoading };
};

export default useProfessionalTodos;
