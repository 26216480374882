import { Box, Container } from '@medely/web-components';
import React, { ReactElement, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import TopBar from 'containers/TopBar';
import Unimpersonate from 'components/Unimpersonate';
import useCurrentUser from 'hooks/useCurrentUser';
import { useConfigurationContext } from '@medely/ui-kit';
import { isAgencyAccount, isSaasAccount } from 'utils/account';
import useSnackbar from 'hooks/useSnackbar';
import ProfessionalTermsOfServiceModal from 'components/TermsOfService/ProfessionalTermsOfServiceModal';
import ProViewContent from 'components/ProViewContent';
interface IProViewProps {
  children?: ReactNode | ReactNode[];
}

const ProView = ({ children }: IProViewProps): ReactElement => {
  const { isApplicant, currentUser } = useCurrentUser();
  const snackbar = useSnackbar();
  const config = useConfigurationContext();
  const isNonSaasOrAgencyApplicant =
    isApplicant && !(isSaasAccount(currentUser) || isAgencyAccount(currentUser));

  return (
    <>
      {isNonSaasOrAgencyApplicant && <Redirect to={`${config.root}/application`} />}
      <Box sx={{ overflow: 'auto', height: '100vh' }} id="pro-view-root">
        <Unimpersonate />
        <TopBar />
        <ProfessionalTermsOfServiceModal
          onError={(message) => {
            snackbar.error(null, message);
          }}
          onSuccess={() => {
            snackbar.success('Success');
          }}
        />
        <Container maxWidth="sm">
          <ProViewContent>{children}</ProViewContent>
        </Container>
      </Box>
    </>
  );
};

export default ProView;
