import { gql } from 'graphql-request';

export const GET_WORK_HISTORIES = gql`
  query WorkHistories($input: WorkHistoryQueryInput) {
    workHistories(input: $input) {
      id
      clinical
      currently_work_here
      description
      employment_type
      end_date
      floating
      floating_details
      hours_per_week
      location
      location_is_teaching
      location_number_of_beds
      location_trauma_level
      location_type_id
      missing_information_list
      organization_name
      position {
        id
      }
      position_title
      start_date
      state_id
      travel_agency
      unit
      clinical
    }
  }
`;

export const GET_WORK_GAPS = gql`
  query WorkGaps($input: WorkGapQueryInput) {
    workGaps(input: $input) {
      id
      start_date
      end_date
      explanation
      location
      state_id
      state {
        abbreviation
      }
    }
  }
`;

export const GET_UNEXPLAINED_WORK_GAPS = gql`
  query UnexplainedWorkGaps {
    unexplainedWorkGaps {
      earlier_experience_id
      earlier_experience_type
    }
  }
`;

export const GET_EDUCATIONS = gql`
  query Educations($input: EducationQueryInput) {
    educations(input: $input) {
      city
      degree_type
      end_date
      field_of_study
      id
      school_name
      start_date
      state_id
    }
  }
`;
