import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { GET_TIMECARD } from '../graphql/timecardsQueries';
import { GqlRequest, useNetworkRequest } from './useNetworkRequest';

const getTimecard = async (queryContext: QueryFunctionContext, gqlRequest: GqlRequest) => {
  const {
    queryKey: [_entity, id],
  } = queryContext;
  const { timecard } = await gqlRequest(GET_TIMECARD, { id });
  return timecard;
};

export const useTimecard = (id: number) => {
  const { gqlRequest } = useNetworkRequest();
  const enabled = !!id;
  const { data, isLoading, isInitialLoading } = useQuery(
    ['timecard', id],
    (queryContext) => getTimecard(queryContext, gqlRequest),
    {
      enabled,
    },
  );

  return { timecard: data, isLoading: enabled ? isLoading : isInitialLoading };
};

export default useTimecard;
