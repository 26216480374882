import { gql } from 'graphql-request';
import {
  DEFAULT_GRAPHQL_FRAGMENT,
  type GraphQLFragment,
  type IsAgencyAccountInput,
  isAgencyAccount,
} from '../utils';

export const getDisputesQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceDisputesFragment',
      fragment: gql`
        fragment MarketplaceDisputesFragment on Dispute {
          job {
            job_billing_sums {
              payout_total_amount_cents
            }
          }
        }
      `,
    };
  }

  return gql`
    ${fragment?.fragment}

    query Disputes($input: DisputeQueryInput) {
      disputes(input: $input) {
        ${fragment?.name}
        id
        job {
          id
          job_billing_sums {
            payout_total_amount_cents
          }
          location {
            name
          }
          starts_date
        }
      }
      _disputesMeta(input: $input) {
        count
      }
    }
  `;
};

export const getDisputeQuery = (account: IsAgencyAccountInput) => {
  let fragment: GraphQLFragment | undefined = DEFAULT_GRAPHQL_FRAGMENT;

  if (!isAgencyAccount(account)) {
    fragment = {
      name: '...MarketplaceDisputeFragment',
      fragment: gql`
        fragment MarketplaceDisputeFragment on Dispute {
          job {
            job_billing_sums {
              payout_total_amount_cents
            }
          }
          adjusted_total_payout_cents
          original_total_payout_cents
        }
      `,
    };
  }

  return gql`
      ${fragment?.fragment}

      query Dispute($id: Int!) {
          dispute(id: $id) {
              ${fragment?.name}
              adjusted_clock_in
              adjusted_clock_out
              adjusted_meal_break_minutes
              created_at
              id
              reason
              job {
                  approved_clock_in
                  approved_clock_out
                  current_ends_time
                  current_starts_time
                  ends_time
                  facility_number
                  id
                  job_billing_sums {
                      total_hours
                  }
                  location {
                      name
                      timezone_lookup
                  }
                  positions {
                      display_name
                  }
                  shift_type
                  starts_date
                  starts_time
                  status
              }
              original_clock_in
              original_clock_out
              original_meal_break_minutes
              original_total_payout_cents
              professional_notes
              status
              total_adjusted_minutes
              updated_at
          }
      }
  `;
};
