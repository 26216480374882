import { ICondition } from '@medely/types';
import { GET_CONDITIONS } from '../graphql';
import { useCurrentUser } from './useCurrentUser';
import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { useNetworkRequest, GqlRequest } from './useNetworkRequest';

type Return = {
  conditions: ICondition[];
  isLoading: boolean;
};

const fetchMissingConditions = async (
  queryContext: QueryFunctionContext,
  gqlRequest: GqlRequest,
) => {
  const {
    queryKey: [_entity, professional_id, job_id, assignment_id, position_ids, is_premier],
  } = queryContext;
  const { conditions } = await gqlRequest(GET_CONDITIONS, {
    input: {
      search: {
        missing_conditions: {
          for_application: false,
          for_on_boarding: false,
          for_premier_only: !!is_premier,
          professional_id,
          job_id,
          assignment_id,
          position_ids,
        },
      },
    },
  });
  return conditions;
};

export const useMissingConditions = ({
  job_id,
  assignment_id,
  position_ids,
  is_premier,
}: {
  job_id?: number;
  assignment_id?: number;
  position_ids?: number[];
  is_premier?: boolean;
} = {}): Return => {
  const { currentUser } = useCurrentUser();
  const { gqlRequest } = useNetworkRequest();

  const enabled = !!currentUser?.professional;
  const { data, isLoading, isInitialLoading } = useQuery(
    [
      'missingConditions',
      currentUser?.professional?.id,
      job_id,
      assignment_id,
      position_ids,
      is_premier,
    ],
    (queryContext) => fetchMissingConditions(queryContext, gqlRequest),
    {
      enabled,
      refetchInterval: 15000,
    },
  );
  return {
    conditions: data ?? [],
    isLoading: enabled ? isLoading : isInitialLoading,
  };
};
