import { gql } from 'graphql-request';

export const GET_ALL_PREMIER_QUALIFICATIONS = gql`
  query PremierQualifications($input: QualificationQueryInput) {
    qualifications(input: $input) {
      id
      slug
      short_name
      qualification_type
      qualification_attributes {
        id
        name
        data
        input_source
        field_type
        active
      }
    }
  }
`;

export const GET_SKILLS_ASSESSMENTS = gql`
  query SkillsAssemssments($input: ProfessionalQualificationQueryInput) {
    professionalQualifications(input: $input) {
      id
      status
      professional_qualification_attributes {
        id
        type
        value
        qualification_attribute {
          id
          name
          input_source
          field_type
          active
        }
      }
      qualification {
        id
        long_name
        qualification_type
      }
    }
  }
`;
