import { useAnalytics } from '@medely/ui-kit';
import useCurrentUser from 'hooks/useCurrentUser';

export type TermsOfServiceForms =
  | 'Accept Invite'
  | 'Account Setup'
  | 'Application Account Info'
  | 'Modal';

const useTermsOfServiceAnalytics = (formName: TermsOfServiceForms) => {
  const { currentUser } = useCurrentUser();
  const analytics = useAnalytics();

  const metadata = {
    form: formName,
    professional_id: currentUser?.professional?.id,
  };

  return {
    trackCompleted: () => analytics.track('Professional Terms of Service Completed', metadata),
    trackViewed: () => analytics.track('Professional Terms of Service Viewed', metadata),
  };
};

export default useTermsOfServiceAnalytics;
