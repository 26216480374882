import { gql } from 'graphql-request';

export const GET_CANCELLATION_REASONS = gql`
  query CancellationReasons($input: CancellationReasonQueryInput) {
    cancellationReasons(input: $input) {
      id
      label
    }
  }
`;
