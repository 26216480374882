import { gql } from 'graphql-request';

export const GET_TAX_FORMS = gql`
  query TaxForms($input: TaxFormQueryInput) {
    taxForms(input: $input) {
      id
      year
    }
  }
`;

export const GET_TAX_FORM_URL = gql`
  query TaxForm($id: Int!) {
    taxForm(id: $id) {
      tax_form
    }
  }
`;
