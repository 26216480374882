import { gql } from 'graphql-request';

export const GET_APP_VERSION = gql`
  query AppVersion($appName: String!) {
    appVersion(appName: $appName) {
      appName
      major
      minor
      patch
    }
  }
`;
