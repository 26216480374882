import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

export default function useHandleError(error?: Error | null) {
  const snackbar = useSnackbar();

  const handleError = (error) => {
    console.error('error', error);
    if (!window.Cypress) {
      let message = 'Sorry, something went wrong';
      if (error.response?.status === 401) {
        message = 'You do not have permission to perform this action';
      }
      if (error.response?.status < 500) {
        const railsError = error.response?.data?.message;
        const gqlError = error.response?.errors?.map(({ message }) => message)?.join(', ');
        const jsError = error.message;
        message = railsError || gqlError || jsError;
      }
      snackbar.enqueueSnackbar(message, { variant: 'error' });
    }
    return error;
  };

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return handleError;
}
