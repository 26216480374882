import { useCurrentUser } from '@medely/pro-multi-platform';
import { Box, MenuItem } from '@medely/web-components';
import React from 'react';
import { SettingsMenuItemsProps } from '../SettingsDropdown';
import { useHistory } from 'react-router';
import { ReferralTag } from 'components/ReferralTag';

export const MarketplaceSettingsMenu = ({ handleClose }: SettingsMenuItemsProps) => {
  const { isDeactivated, isApplicant } = useCurrentUser();
  const history = useHistory();
  return (
    <>
      {!isApplicant && (
        <>
          {!isDeactivated && (
            <MenuItem
              onClick={() => {
                history.push('/profile');
                handleClose();
              }}
            >
              My Profile
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              history.push('/settings');
              handleClose();
            }}
          >
            Settings
          </MenuItem>
        </>
      )}
      {!isDeactivated && (
        <Box position="relative">
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            Refer a Friend
          </MenuItem>
          <ReferralTag id="global_header" />
        </Box>
      )}
    </>
  );
};
