export const USER_SOURCE = {
  email: 'e',
  sms: 's',
  other: 'o',
};

export const OFFER_SOURCE = {
  e: 'email',
  s: 'sms',
  o: 'other',
};

export const UTM_SOURCE = {
  notification: 'notification',
};

export enum TrackedEvents {
  ASSIGNMENT_VIEW = 'ASSIGNMENT_VIEW',
  JOB_VIEW = 'JOB_VIEW',
  ASSIGNMENT_LIST_VIEW = 'ASSIGNMENT_LIST_VIEW',
  JOB_LIST_VIEW = 'JOB_LIST_VIEW',
  QUALIFICATION_EDIT_STARTED = 'QUALIFICATION_EDIT_STARTED',
  QUALIFICATION_EDIT_FINISHED = 'QUALIFICATION_EDIT_FINISHED',
}
