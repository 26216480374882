import { gql } from 'graphql-request';

export const GET_RECRUITER = gql`
  query Recruiters($id: Int!) {
    administrator(id: $id) {
      id
      account_id
      account {
        id
        first_name
      }
    }
  }
`;
