import { AxiosError } from 'axios';
import { useSnackbar as useNotistackSnackbar } from 'notistack';

const useSnackbar = () => {
  const { enqueueSnackbar } = useNotistackSnackbar();
  return {
    default: (message: string) => enqueueSnackbar(message),
    success: (message: string) => enqueueSnackbar(message, { variant: 'success' }),
    error: (error: AxiosError | null, message?: string) =>
      enqueueSnackbar(
        message ?? error.response?.data?.error ?? error.response?.data?.message ?? error.message,
        { variant: 'error' },
      ),
    warning: (message: string) => enqueueSnackbar(message, { variant: 'warning' }),
  };
};

export default useSnackbar;
