import { gql } from 'graphql-request';

export const GET_CURRENT_USER = gql`
  query Me {
    me {
      id
      agency_id
      agency {
        name
      }
      user_hash
      account_type
      name
      first_name
      middle_name
      last_name
      address_first
      address_second
      address_city
      state_id
      address_zip
      phone_number
      email
      notification_settings
      coordinates {
        latitude
        longitude
      }
      state {
        id
        abbreviation
        name
        rectangle_image
      }
      professional {
        id
        signup_assignment_id
        phone_screen_date_and_time
        background_check_authorized
        background_checks {
          id
          report_status
          candidate_status
        }
        drug_screening_completed
        all_time_total_earnings_cents
        cancellation_ratio
        nickname
        kind
        dob
        application_status
        status
        has_markets
        has_item
        has_active_funding_source
        required_item_image
        referral_source_id
        resume
        satisfied_condition_ids
        screening_recruiter_id
        background_check_pqs {
          id
          status
          professional_qualification_attributes {
            qualification_attribute {
              slug
            }
          }
          qualification {
            id
            slug
          }
        }
        markets {
          id
          state_id
          display_name
        }
        professional_positions {
          position {
            id
            display_name
            name
          }
          position_id
          completed_onboarding_at
          has_recent_experience
        }
        profile_strength
        positions {
          id
          display_name
          kind
        }
        specialties {
          id
          label
          name
        }
        stripe_accounts {
          id
          first_name
          last_name
          company_name
          tax_classification_id
          tax_form_preference
          active
          address_first
          address_second
          address_city
          state_id
          state {
            id
            name
            abbreviation
          }
          address_zip
          phone_number
          external_accounts {
            id
            last4
          }
        }
        w2_eligible
        w2_enabled
      }
      company_id
      company {
        id
        name
        logo
        saas_credentialing_enabled
        saas_scheduling_enabled
      }
    }
  }
`;

export const PERMISSIONS_QUERY = gql`
  query GetSchemaPermisionsForRequests {
    accountRequestPermissions
  }
`;
