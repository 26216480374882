import { TermsOfServiceModal, TermsOfServiceModalProps } from '@medely/web-components';
import { gateNames } from 'constants/statsig';
import useCurrentUser from 'hooks/useCurrentUser';
import React from 'react';
import { useGate } from 'statsig-react';
import isDevOrStaging from 'utils/isDevOrStaging';
import useTermsOfServiceAnalytics from './useTermsOfServiceAnalytics';

type ProfessionalTermsOfServiceModalProps = Pick<TermsOfServiceModalProps, 'onError' | 'onSuccess'>;

const ProfessionalTermsOfServiceModal = ({
  onError,
  onSuccess,
}: ProfessionalTermsOfServiceModalProps) => {
  const { isApplicant, currentUser, isAgency, isSaas } = useCurrentUser();
  const { trackViewed, trackCompleted } = useTermsOfServiceAnalytics('Modal');

  const { value: tosOn } = useGate(gateNames.tosOn);
  const isImpersonating = window.localStorage.getItem('impersonating') === 'true';
  const isStaging = isDevOrStaging();
  const dontShowRealContract = isStaging || isImpersonating;

  const showTosModal = tosOn && !isImpersonating && !isSaas && !isAgency;

  if (!currentUser) {
    return <></>;
  }

  return (
    <>
      {showTosModal && (
        <TermsOfServiceModal
          disabled={isApplicant}
          groupKey={
            dontShowRealContract
              ? 'embedded-ivsdh93z5'
              : process.env.REACT_APP_PRO_TOS_MODAL_GROUP_KEY
          }
          onDisplayed={trackViewed}
          onError={onError}
          onSuccess={() => {
            trackCompleted();
            onSuccess?.call(this);
          }}
          signerId={
            isImpersonating ? `impersonating_${currentUser?.email}` : `${currentUser?.email}`
          }
        />
      )}
    </>
  );
};

export default ProfessionalTermsOfServiceModal;
