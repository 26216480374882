import { gql } from 'graphql-request';

export const GET_CONTRACTS = gql`
  query Contracts($input: ContractQueryInput) {
    contracts(input: $input) {
      id
      contract_type
      signed_at
    }
  }
`;
