import config from 'config';
import Rollbar, { LogArgument } from 'rollbar';

declare global {
  interface Window {
    Rollbar: Rollbar;
  }
}

const getRollbarEnv = () => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_STAGING === 'true' ? 'staging' : 'production';
  }
};

export const rollbarConfig = {
  accessToken: config.rollbar.postClientItemKey,
  environment: getRollbarEnv(),
};

window.Rollbar = new Rollbar(rollbarConfig);

type NotifyRollbarArgs = {
  errorLabel?: string;
  args: LogArgument;
  level?: 'ERROR' | 'WARNING';
};

export const notifyRollbar = ({
  errorLabel = 'Error',
  args,
  level = 'ERROR',
}: NotifyRollbarArgs) => {
  if (process.env.NODE_ENV === 'production') {
    if (level === 'WARNING') {
      window.Rollbar.warning(errorLabel, args);
    } else {
      window.Rollbar.error(errorLabel, args);
    }
  }
};
