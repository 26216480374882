import { gql } from 'graphql-request';

export const GET_PROFESSIONAL_POSITIONS = gql`
  query ProfessionalPositions($input: ProfessionalPositionQueryInput) {
    professionalPositions(input: $input) {
      id
      professional_id
      position_id
      years_of_experience
      completed_onboarding_at
      has_recent_experience
    }
  }
`;

export const GET_PROFESSIONAL_POSITION = gql`
  query ProfessionalPosition($id: Int!) {
    professionalPosition(id: $id) {
      id
      professional_id
      position_id
      years_of_experience
      completed_onboarding_at
      has_recent_experience
    }
  }
`;
