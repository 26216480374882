import { gql } from 'graphql-request';

const PROFESSIONAL_REFERENCE_FRAGMENT = gql`
  fragment professionalReferenceFields on ProfessionalReference {
    id
    first_name
    last_name
    title
    department
    location
    start_date
    end_date
    current_position
    email
    phone_number
    created_at
    uuid
    work_history_id
    work_history {
      id
      organization_name
      unit
    }
  }
`;

export const GET_REFERENCES = gql`
  query ProfessionalReferences($input: ProfessionalReferenceQueryInput) {
    professionalReferences(input: $input) {
      ...professionalReferenceFields
      status
    }
  }
  ${PROFESSIONAL_REFERENCE_FRAGMENT}
`;

export const GET_REFERENCE = gql`
  query ProfessionalReference($id: Int!) {
    professionalReference(id: $id) {
      ...professionalReferenceFields
    }
  }
  ${PROFESSIONAL_REFERENCE_FRAGMENT}
`;
