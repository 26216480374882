import { useMediaQuery } from '@medely/web-components';
import { useTheme } from '@medely/web-components/themes';

type Device = 'mobile' | 'desktop';

const useDevice = (): Device => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return isMobile ? 'mobile' : 'desktop';
};

export default useDevice;
