import { useRef } from 'react';
import { v4 } from 'uuid';

export const useRandomIdRef = () => {
  const id = useRef<string>(v4());

  const refresh = () => {
    id.current = v4();
  };

  const get = () => {
    return id.current;
  };

  return { get, refresh };
};

export default useRandomIdRef;
