import { gql } from 'graphql-request';

export const GET_TIMECARD = gql`
  query Timecard($id: Int!) {
    timecard(id: $id) {
      id
      assignment_id
      assignment {
        track_unpaid_breaks
        is_w2
        is_travel
        require_physical_signature
        payout_base_hourly_rate_cents
        starts_date
        ends_date
        number_of_shifts_per_week
        hours_per_shift
        primary_location {
          id
          name
          timezone_lookup
        }
      }
      starts_time
      ends_time
      status
      jobs {
        id
        shift_type
        starts_date
        status
        cancellation_fee_charge_hours
        current_starts_time
        current_ends_time
        cancellation_reason_id
        guaranteed_minimum_hours
        created_by_account_id
        job_billing_sums {
          total_hours
        }
        assignment {
          is_w2
          float_requirements
          primary_location {
            id
          }
        }
        last_cancellation {
          other_reason
        }
        location {
          timezone_lookup
          id
          name
          unit
        }
        expense_adjustments {
          taxable_type
          amount_cents
          created_at
        }
        breaks {
          id
          waived
          duration_minutes
          starts_time
        }
      }
      submit_by
    }
  }
`;

export const GET_TIMECARDS = gql`
  query Timecards($input: TimecardQueryInput) {
    timecards(input: $input) {
      id
      status
      starts_time
      ends_time
      jobs {
        location {
          name
        }
        job_billing_sums {
          total_hours
        }
        id
        status
        shift_type
        starts_date
        ends_date
        starts_time
        ends_time
        current_starts_time
        current_ends_time
        breaks {
          id
          waived
          duration_minutes
          starts_time
        }
      }
      assignment_id
      assignment {
        is_w2
        is_travel
        require_physical_signature
        float_requirements
        starts_time
        ends_time
        starts_date
        ends_date
        track_unpaid_breaks
        status
        primary_location {
          name
          full_address
          timezone_lookup
          id
        }
        locations {
          name
          id
          unit
        }
        hours_per_shift
        number_of_shifts_per_week
      }
      submit_by
      saas_company_id
    }
    _timecardsMeta(input: $input) {
      count
    }
  }
`;
