import { gql } from 'graphql-request';

export const GET_TAX_CLASSIFICATIONS = gql`
  query TaxClassifications($input: TaxClassificationQueryInput) {
    taxClassifications(input: $input) {
      id
      label
    }
  }
`;
