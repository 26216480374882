import { gql } from 'graphql-request';

export const GET_MARKETS = gql`
  query Markets($input: MarketQueryInput) {
    markets(input: $input) {
      id
      display_name
    }
  }
`;
