import { useEffect } from 'react';

interface TrackingUser {
  first_name: string;
  last_name: string;
  email: string;
}

declare global {
  interface Window {
    extole: any;
  }
}

const EXTOLE_EXTERNAL_SCRIPT = 'https://share.medely.com/core.js';

const loadScript = (src: string) =>
  new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });

const setupTracker = () => {
  (function (c, e, k, l, a) {
    c[e] = c[e] || {};
    for (c[e].q = c[e].q || []; a < l.length; ) {
      k(l[a++], c[e]);
    }
  })(
    window,
    'extole',
    function (c, e) {
      e[c] =
        e[c] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          e.q.push([c, arguments]);
        };
    },
    ['createZone'],
    0,
  );
};

const extoleAvailable = (): boolean => Boolean(window.extole);

const useExtole = () => {
  const shouldTrack = true; // process.env.NODE_ENV === 'production';

  const setup = async () => {
    if (!shouldTrack) {
      return false;
    }

    try {
      if (!extoleAvailable()) {
        await loadScript(EXTOLE_EXTERNAL_SCRIPT);
      }
      setupTracker();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const isAvailable = () => shouldTrack && extoleAvailable();

  const trackApplicationSubmitted = (user: TrackingUser) => {
    if (isAvailable()) {
      window.extole.createZone({
        name: 'application_submitted',
        data: { ...user },
      });
    }
  };

  useEffect(() => {
    setup();
  }, []);

  return {
    shouldTrack,
    isAvailable,
    trackApplicationSubmitted,
    setup,
  };
};

export default useExtole;
