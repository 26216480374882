import { gql } from 'graphql-request';

export const GET_SPECIALTIES = gql`
  query Specialities($input: SpecialtyQueryInput) {
    specialties(input: $input) {
      id
      label
      name
    }
  }
`;

export const GET_SPECIALTIES_BY_POSITION = gql`
  query SpecialtiesByPosition {
    positions {
      id
      display_name
      specialties {
        id
        label
        name
      }
    }
  }
`;
