export const openLink = (url: string, target = '_self') => {
  const win = window.open(url, target);
  if (win) {
    win.focus();
  }
};

export default function openInNewTab(url: string) {
  openLink(url, '_blank');
}
