/* eslint-disable medely/noUseCallbackUseMemo */
import { useAnalytics } from '@medely/ui-kit';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';

enum CONTEXTS {
  PROFESSIONAL_SIGN_UP = 'Professional Sign Up',
}

enum ACTIONS {
  VIEWED = 'Viewed',
}

const DEFAULT_DEBOUNCE_TIME = 500;

interface IUseSignUpEvent {
  trackSignUpView: () => void;
}

export const useSignUpEvent = (): IUseSignUpEvent => {
  const analytics = useAnalytics();

  const trackSignUpView = useCallback(
    debounce(() => {
      analytics.track({
        context: CONTEXTS.PROFESSIONAL_SIGN_UP,
        subcontext: null,
        action: ACTIONS.VIEWED,
      });
    }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return {
    trackSignUpView,
  };
};

export default useSignUpEvent;
